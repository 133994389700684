<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Session
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Session Name"
            rules="required"
          >
            <b-form-group
              label="Session Name"
              label-for="course-name"
            >
              <b-form-input
                id="full-name"
                v-model="userData.title"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Awesome session"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

         

        

    <validation-provider
            #default="validationContext"
            name="Session Duration "
              rules="required"
          >
 <b-row>
  
           
           <b-col md="7" class="col-md-7">
            <b-form-group
              label="Session Start Date"
              label-for="course-name"
            >
             <b-form-datepicker
      v-model="userData.startdate"

       locale="en-US"
       :date-format-options="{ year: 'numeric', month: 'numeric', day: '2-digit'  }"
      
      :min="min"
      :max="max" 
    />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

              </b-col>

 <b-col md="5" class="col-md-5">
  <b-form-group
              label="Session Start Time"
              label-for="course-name"
            >
              <b-form-timepicker
      id="datepicker-invalid"
      v-model="userData.timeStart"

      class="mb-2"
    />

    
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
    </b-col>

    

          
          
 </b-row>
      </validation-provider>


 
 
  


       <validation-provider
            #default="validationContext"
            name="Session EndDate "
              rules="required"
          >
 <b-row>
  
           
              <b-col md="7" class="col-md-7">
            <b-form-group
              label="Session End Date"
              label-for="course-name"
            >
             <b-form-datepicker
      v-model="userData.enddate"
      :min="min"
      :max="max"
       locale="en-US"
       :date-format-options="{ year: 'numeric', month: 'numeric', day: '2-digit'  }"
       
    />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

              </b-col>

 <b-col md="5" class="col-md-5">
  <b-form-group
              label="Session End Time"
              label-for="course-name"
            >
              <b-form-timepicker
      id="datepicker2-invalid"
      v-model="userData.timeEnd"
      
      class="mb-2"
    />

    
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
    </b-col>

    

          
          
 </b-row>
      </validation-provider>

            <validation-provider
            #default="validationContext"
            name="Session Duration in minutes "
           
          >
            <b-form-group
              label="Session Duration in minutes "
              label-for="course-name"
            >
              <b-form-input
                id="full-name"
                v-model="userData.estimatedDurationMinutes"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="120"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

           <validation-provider
            #default="validationContext"
            name="Session Speaker "
           
          >
            <b-form-group
              label="Session Speaker "
              label-for="course-name"
            >
              <b-form-input
                id="full-name"
                v-model="userData.speaker"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="120"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


 <validation-provider
            #default="validationContext"
            name="Session Location "
           
          >
            <b-form-group
              label="Session Location"
              label-for="course-name"
            >
              <b-form-input
                id="full-name"
                v-model="userData.place"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Session venue"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


       <validation-provider
            #default="validationContext"
            name="Session Latitude "
            
          >
 <b-row>
  
           
           <b-col md="6" class="col-md-6">
            <b-form-group
              label="Venue Latitude"
              label-for="course-name"
            >
            <b-form-input
                id="full-name"
                v-model="userData.latitude"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Venue Latitude"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

              </b-col>

 <b-col md="6" class="col-md-6">
  <b-form-group
              label="Venue Longitude"
              label-for="course-name"
            >
               <b-form-input
                id="full-name"
                v-model="userData.longitude"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Venue Longitude"
              />

    
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
    </b-col>

    

          
          
 </b-row>
      </validation-provider>
      


              <validation-provider
            #default="validationContext"
            name="Venue Address "
           
          >
            <b-form-group
              label="Venue Address"
              label-for="course-name"
            >
             <b-form-textarea
        id="textarea-small"
        size="sm"
        placeholder="Venue address"
      />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- User Role -->
        
          
        

        

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BFormDatepicker, 
    BFormTimepicker,
    BCardText,
     BForm, 
    BFormGroup, 
    BFormInput,
     BFormInvalidFeedback,
      BButton,
      BRow, BCol,BFormTextarea
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'

import router from '@/router'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import CourseTypePicker from '../components/SessionTypePicker.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    CourseTypePicker,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    BCardText,
    BFormTimepicker,BRow, BCol ,BFormTextarea

  },
  directives: {
    Ripple
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    }
  },
  data()  {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    // 15th two months prior
    const minDate = new Date(today);
    minDate.setMonth(minDate.getMonth() - 2);
    minDate.setDate(15);

    // 15th in two months
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth() + 12);
    maxDate.setDate(15);

    return {
      value: '',
      min: minDate,
      max: maxDate,
      codeMinMax:null,
      required:true,
      alphaNum:null,
      selectedTime: null,
      codeValidation:null
    }
  } ,
  methods:{
 courseModeUpdated(payload) {
      console.log("Catefy result in info ");
      console.log(payload);
      if (payload==null)
      {
   this.userData.coursemode = "";
      } else
      this.userData.coursemode = payload.name;
    },
  },
  setup(props, { emit }) {
    const blankUserData = {
    
      title: '',
      courseCode: '',
     
      coursemode:'',
   
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
        console.log("Session ADD PAGE  Create triggered");
   
     const incomingcourse= router.currentRoute.params.id;
     console.log("incomingcourse"+incomingcourse);
 userData.value.courseguid=incomingcourse;

      store.dispatch('course-session/addCourse', userData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
